import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { LiveMapService } from '../live-map.service';

@Component({
  selector: 'app-map-order-toast',
  templateUrl: './map-order-toast.component.html',
  styleUrls: ['./map-order-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [    
    trigger('toastEnter', [    
      transition(':enter',[
        style({ transform: 'translateY(-100%)'}),
        animate(300)
      ]),
    ])  
  ]
})
export class MapOrderToastComponent extends Toast implements OnInit {

  order;

  constructor(protected toastrService: ToastrService, private liveMapService: LiveMapService,
    public toastPackage: ToastPackage, private ref: ChangeDetectorRef) { 
      
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    this.order = JSON.parse(this.toastPackage.message);
  }

  update() {
    this.order = JSON.parse(this.toastPackage.message);
    this.ref.markForCheck();
  }

  viewOnMap(driver) {
    this.liveMapService.setCurrentMapCursor(driver);
  }
}
