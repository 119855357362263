import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AccordionComponent} from './accordion/accordion.component';
import {AccordionItem} from "./accordion-item.directive";
import {AccordionHeader} from "./accordion-header.directive";
import {AccordionContent} from "./accordion-content.directive";
import {AccordionTitle} from "./accordion-title.directive";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


@NgModule({
  declarations: [
    AccordionComponent,
    AccordionItem,
    AccordionContent,
    AccordionTitle,
    AccordionHeader,
  ],
  exports: [
    AccordionComponent,
    AccordionItem,
    AccordionContent,
    AccordionTitle,
    AccordionHeader,
  ],
  imports: [
    CommonModule,
  ]
})
export class AccordionModule {
}
