import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LiveMapService {

    private viewOnMapSubject: BehaviorSubject<any>;
    viewOnMapObs: Observable<any>;
    private http: HttpClient;

    constructor(handler: HttpBackend) {
        this.viewOnMapSubject = new BehaviorSubject<any>(null);
        this.viewOnMapObs = this.viewOnMapSubject.asObservable();
        this.http = new HttpClient(handler);
    }

    setCurrentMapCursor(drivers) {
        this.viewOnMapSubject.next(drivers);
    }

    public getAccountInfo(token: string) {
        const authHeader = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        return this.http.get<any>(`${environment.standardApiUrl}/account/getAccountInfo`, { headers: authHeader })
            .pipe(take(1));
    }

    public isMasterCompany(companyId: number, token: string) {
        const authHeader = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const params = new HttpParams()
        .append('companyId', companyId.toString());

        return this.http.get<any>(`${environment.standardApiUrl}/company/isMasterCompany`, { headers: authHeader, params })
            .pipe(take(1));
    }
}
