import {RouteInfo} from './sidebar.metadata';

export const PRO_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.HOME.TEXT",
    moduleName: "dashboard",
    iconType: "material-icons-outlined",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["superadmin"],
    submenu: [],
  },

  {
    path: "/admin/orders/list",
    title: "MENUITEMS.ORDERS.TEXT",
    moduleName: "orders",
    iconType: "material-icons-outlined",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["superadmin"],
    submenu: [],
  },

  {
    path: "",
    title: "MENUITEMS.GROUPS.TEXT",
    moduleName: "groups",
    iconType: "material-icons-outlined",
    icon: "people",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["superadmin"],
    submenu: [
      {
        path: "/admin/groups/list",
        title: "MENUITEMS.GROUPS.LIST",
        moduleName: "groups",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },

  {
    path: "",
    title: "MENUITEMS.PARTNERS.TEXT",
    moduleName: "partners",
    iconType: "material-icons-outlined",
    icon: "emoji_transportation",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["superadmin"],
    submenu: [
      {
        path: "/admin/partners/list",
        title: "MENUITEMS.PARTNERS.LIST",
        moduleName: "partners",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },

  {
    path: "",
    title: "MENUITEMS.VEHICLES.TEXT",
    moduleName: "vehicles",
    iconType: "material-icons-outlined",
    icon: "local_taxi",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["superadmin"],
    submenu: [
      {
        path: "/admin/vehicles/list",
        title: "MENUITEMS.VEHICLES.LIST.LIST-VEHICLES",
        moduleName: "vehicles",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },

  {
    path: "",
    title: "MENUITEMS.DRIVERS.TEXT",
    moduleName: "drivers",
    iconType: "material-icons-outlined",
    icon: "people",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["superadmin"],
    submenu: [
      {
        path: "/admin/drivers/sync",
        title: "MENUITEMS.DRIVERS.SYNC",
        moduleName: "drivers",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/drivers/list",
        title: "MENUITEMS.DRIVERS.LIST.LIST-DRIVERS",
        moduleName: "drivers",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },

  {
    path: "",
    title: "MENUITEMS.SETTINGS.TEXT",
    moduleName: "settings",
    iconType: "material-icons-outlined",
    icon: "settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["superadmin"],
    submenu: [
      {
        path: "/admin/settings/cover-areas",
        title: "MENUITEMS.SETTINGS.LIST.COVER_AREAS",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }, {
        path: "/admin/settings/session-configs",
        title: "MENUITEMS.SETTINGS.LIST.SESSIONS",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },


  // Common Modules

  {
    path: '',
    title: 'Authentication',
    moduleName: 'authentication',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/authentication/signin',
        title: 'Sign In',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/authentication/page404',
        title: '404 - Not Found',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/authentication/page500',
        title: '500 - Server Error',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  }
];
