import { IMqttServiceOptions } from "ngx-mqtt";
import { environment } from "src/environments/environment";

class MqttServiceOptions implements IMqttServiceOptions {
    hostname?: string;
    port?: number;
    path?: string;
    username?: string;
    password?: string;
    protocol?: "wss" | "ws";
    connectTimeout?: number;
    reconnectPeriod?: number;
    connectOnCreate?: boolean;
    clean?: boolean;

    constructor(builder: MqttServiceOptionsBuilder) {
        this.hostname = builder.hostname;
        this.port = builder.port;
        this.path = builder.path
        this.username = builder.username;
        this.password = builder.password;
        this.protocol = builder.protocol;
        this.connectTimeout = builder.connectTimeout;
        this.reconnectPeriod = builder.reconnectPeriod;
        this.connectOnCreate = builder.connectOnCreate;
        this.clean = builder.clean;
    }
}

export class MqttServiceOptionsBuilder {

    hostname = environment.socketServerUrl;
    port = environment.socketServerPort;
    path = '/mqtt'
    username: string;
    password: string;
    protocol = 'wss' as "wss" | "ws";
    connectTimeout = 4000;
    reconnectPeriod = 4000;
    connectOnCreate = true;
    clean = true;

    setUsername(value: string): MqttServiceOptionsBuilder {
        this.username = value;
        return this;
    }

    setPassword(value: string): MqttServiceOptionsBuilder {
        this.password = value;
        return this;
    }

    build(): MqttServiceOptions {
        return new MqttServiceOptions(this);
    }
}