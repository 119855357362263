import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment/moment";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public languages: string[] = ["ro", "en"];

  get currentLocale(): string {
    return this.translate.currentLang;
  }

  constructor(public translate: TranslateService) {
    translate.addLangs(this.languages);

    let browserLang;
    if (localStorage.getItem("lang")) {
      browserLang = localStorage.getItem("lang");
    } else {
      browserLang = translate.getBrowserLang();
    }

    browserLang = browserLang.match(/ro|en/) ? browserLang : "ro";

    translate.use(browserLang);
    moment.locale(browserLang);
  }

  public setLanguage(lang) {
    localStorage.setItem("lang", lang);
    window.location.reload();
  }
}
