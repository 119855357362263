<div @toastEnter class="row" [ngClass]="order.type + (order.upcoming ? ' upcoming' : ' ')" (click)="viewOnMap(order.driver)">
    <div class="col-1 side">
        <i [class]="order.sourceIcon" class="mb-sm"></i>
        <i [class]="order.paymentIcon" class="mb-sm"></i>
        <i *ngIf="order.flavorIcon" class="mb-sm" [class]="order.flavorIcon"
            style="color: #fad733; margin-top: 15px;"></i>
        <span [innerHtml]="order.driverTypeIcon"></span>
    </div>
    <div class="col main no-wrap">
        <div class="time-area">{{order.time}}</div>
        <div class="title">{{order.title}}</div>
        <div [innerHtml]="order.body"></div>
    </div>
</div>