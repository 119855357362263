import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {LookupItem} from "../interfaces/lookup-item.interface";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) {

  }

  lookupLocation(search: string): Observable<LookupItem[]> {
    return this.http
      .get<LookupItem[]>(`${environment.apiUrl}/address/lookup?search=${search}`);
  }

  searchLocation(search: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/address/search?search=${search}`);
  }

  lookupGroup(dto: any): Observable<LookupItem[]> {
    return this.http
      .post<LookupItem[]>(`${environment.apiUrl}/group/lookup`, dto);
  }

  lookupPartner(dto: any): Observable<LookupItem[]> {
    return this.http
      .post<LookupItem[]>(`${environment.apiUrl}/partner/lookup`, dto);
  }

  lookupDispatch(dto: any): Observable<LookupItem[]> {
    return this.http
      .post<LookupItem[]>(`${environment.apiUrl}/dispatch/lookup`, dto);
  }

  lookupVehicle(dto: any): Observable<LookupItem[]> {
    return this.http
      .post<LookupItem[]>(`${environment.apiUrl}/vehicle/lookup`, dto);
  }

  lookupDriver(dto: any): Observable<LookupItem[]> {
    return this.http
      .post<LookupItem[]>(`${environment.apiUrl}/driver/lookup`, dto);
  }

  lookupClient(dto: any): Observable<LookupItem[]> {
    return this.http
      .post<LookupItem[]>(`${environment.standardApiUrl}/client/lookup`, dto);
  }

  lookupVehicleModels(search: string): Observable<LookupItem[]> {
    return this.http
      .get<LookupItem[]>(`${environment.apiUrl}/vehicle/lookupModels?search=${search}`);
  }

  lookupCounties(search: string): Observable<string[]> {
    return this.http
      .get<string[]>(`${environment.apiUrl}/address/lookupCounties?search=${search}`);
  }

  sendPayoutVerificationCode(): Observable<any> {
    return this.http
      .get<string[]>(`${environment.standardApiUrl}/verify/sendPayoutVerificationCode`);
  }
}
