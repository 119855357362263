<div class="container">
  <div class="header">
    <h3>{{ 'confirmAction.confirm' | translate }}</h3>
  </div>

  <div class="content">

    <div class="message">
      <h6>{{ data.message }}</h6>

      <h5 *ngIf="data.name">{{ data.name }}</h5>
    </div>

    <div *ngIf="data.dropdown" class="select-dropdown">
      <h6>{{ 'ui.reason' | translate }}</h6>
      <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="dropdownSelectedValue">
          <mat-option *ngFor="let option of data.dropdown" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="actions">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
      <div class="row">
        <button type="submit" mat-raised-button color="primary" class="mr-3" (click)="closeDialog(DialogResult.Yes)">
          {{ "ui.yes" | translate }}
        </button>

        <button type="button" color="warn" mat-raised-button (click)="closeDialog(DialogResult.No)">
          {{ "ui.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
