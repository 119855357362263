import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerModule} from "ngx-spinner";
import {MaterialModule} from "./material.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AccordionModule} from "./accordion/accordion.module";
import {ConfirmActionComponent} from './confirm-action/confirm-action.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from '@angular/material/select';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DATEPICKER_FORMATS} from "../core/utils/DatepickerFormats";
import {AgmCoreModule} from "@agm/core";
import {CountUpDirective} from "../core/directives/count-up.directive";
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    ConfirmActionComponent,
    CountUpDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    AccordionModule,
    MatButtonModule,
    MatSelectModule,
    NgxSkeletonLoaderModule,

    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBPWAdCvXuJTzd89WY9lWW-c2kZXqSo1bw",
      libraries: ['drawing']
    }),
    TranslateModule.forChild(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    MatSnackBarModule,
    AccordionModule,
    CountUpDirective,
    NgxSkeletonLoaderModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('lang')},
    {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ]
})
export class SharedModule {
}
