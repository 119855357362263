import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogResult } from 'src/app/core/enums/dialog-result.enum';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {

  DialogResult = DialogResult;
  dropdownSelectedValue: string;

  constructor(public dialogRef: MatDialogRef<ConfirmActionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.dropdown) {
      this.dropdownSelectedValue = data.dropdown[0];
    }
  }

  ngOnInit(): void {
  }

  closeDialog(action) {
    let param = {
      action: action,
      selectedValue: this.dropdownSelectedValue
    }
    this.dialogRef.close(param);
  }
}
