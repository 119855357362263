import {NgModule, LOCALE_ID} from '@angular/core';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTabsModule} from "@angular/material/tabs";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layout/header/header.component';
import {PageLoaderComponent} from './layout/page-loader/page-loader.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {RightSidebarComponent} from './layout/right-sidebar/right-sidebar.component';
import {AuthLayoutComponent} from './layout/app-layout/auth-layout/auth-layout.component';
import {MainLayoutComponent} from './layout/app-layout/main-layout/main-layout.component';
import {ErrorInterceptor} from './core/interceptor/error.interceptor';
import {JwtInterceptor} from './core/interceptor/jwt.interceptor';
import {LocationStrategy, HashLocationStrategy, registerLocaleData} from '@angular/common';
import {PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ClickOutsideModule} from 'ng-click-outside';
import {HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {LocaleProvider} from "./core/providers/locale.provider";
import ro from '@angular/common/locales/ro';
import { MqttModule } from 'ngx-mqtt';
import { MqttServiceOptionsBuilder } from './frame/live-map/mqtt-service-options-builder';
import { ToastrModule } from 'ngx-toastr';
import { MapOrderToastComponent } from './frame/live-map/map-order-toast/map-order-toast.component';

registerLocaleData(ro, 'ro');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTabsModule,
    AppRoutingModule,
    HttpClientJsonpModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    CoreModule,
    SharedModule,
    MqttModule.forRoot(new MqttServiceOptionsBuilder),
    ToastrModule.forRoot({
      toastComponent: MapOrderToastComponent,
      disableTimeOut: true,
      tapToDismiss: false,
      toastClass: 'none'
    })
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    LocaleProvider,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
