import {LookupItem} from "../interfaces/lookup-item.interface";

export class BaseEnum {
  static getName(id: number): string {
    let results: string[] = Object.keys(this).filter((key: string) => {
      return this[key] === id;
    });

    return results.length > 0 ? results[0] : '';
  }

  static toArray(): LookupItem[] {
    return Object.keys(this).map((key: any) => {
      return {id: this[key], name: key};
    });
  }
}
