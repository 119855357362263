import {LOCALE_ID, Provider} from '@angular/core';
import {LanguageService} from "../service/language.service";

export class LocaleId extends String {
  constructor(private languageService: LanguageService) {
    super();
  }

  toString(): string {
    return this.languageService.currentLocale;
  }

  valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [LanguageService],
};
