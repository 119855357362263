import {Injectable} from '@angular/core';
import {InConfiguration} from '../core/interfaces/config.interface';
import {Coordinate} from "../core/entities/coordinate";

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData: InConfiguration;

  private counties: any[] = [
    {id: 9, name: "Bucuresti", coordinates: {lat: 44.4274435, lng: 26.1033839}},
    {id: 21, name: "Alba", coordinates: {lat: 46.0811563, lng: 23.5781427}},
    {id: 29, name: "Arad", coordinates: {lat: 46.1801967, lng: 21.3156447}},
    {id: 23, name: "Arges", coordinates: {lat: 44.8560255, lng: 24.8384836}},
    {id: 2, name: "Bacau", coordinates: {lat: 46.5633178, lng: 26.9317597}},
    {id: 25, name: "Bihor", coordinates: {lat: 47.0795096, lng: 21.9082537}},
    {id: 4, name: "Bistrita-Nasaud"},
    {id: 7, name: "Botosani", coordinates: {lat: 47.7527116, lng: 26.6510989}},
    {id: 10, name: "Braila", coordinates: {lat: 45.2819833, lng: 27.9622175}},
    {id: 8, name: "Brasov", coordinates: {lat: 45.6630666, lng: 25.5860807}},
    {id: 16, name: "Buzau", coordinates: {lat: 45.1533458, lng: 26.8211481}},
    {id: 24, name: "Calarasi"},
    {id: 31, name: "Caras-Severin", coordinates: {lat: 45.3131604, lng: 21.8943818}},
    {id: 20, name: "Cluj", coordinates: {lat: 46.7666701, lng: 23.5780583}},
    {id: 34, name: "Constanta", coordinates: {lat: 44.1682684, lng: 28.6132823}},
    {id: 13, name: "Covasna"},
    {id: 5, name: "Dambovita"},
    {id: 1, name: "Dolj", coordinates: {lat: 44.3146372, lng: 23.8056071}},
    {id: 32, name: "Galati", coordinates: {lat: 45.4457345, lng: 28.025476}},
    {id: 22, name: "Giurgiu", coordinates: {lat: 43.9026566, lng: 25.9596737}},
    {id: 33, name: "Gorj"},
    {id: 3, name: "Harghita"},
    {id: 11, name: "Hunedoara", coordinates: {lat: 45.8765165, lng: 22.9172578}},
    {id: 30, name: "Ialomita", coordinates: {lat: 44.5666131, lng: 27.3739983}},
    {id: 26, name: "Iasi", coordinates: {lat: 47.1583353, lng: 27.5808763}},
    {id: 18, name: "Ilfov", coordinates: {lat: 44.4653125, lng: 26.0921834}},
    {id: 36, name: "Maramures", coordinates: {lat: 47.6538184, lng: 23.5727045}},
    {id: 37, name: "Mehedinti"},
    {id: 40, name: "Mures", coordinates: {lat: 46.541686, lng: 24.5541591}},
    {id: 19, name: "Neamt"},
    {id: 42, name: "Olt", coordinates: {lat: 44.1151507, lng: 24.3473981}},
    {id: 17, name: "Prahova", coordinates: {lat: 44.9461737, lng: 26.0217608}},
    {id: 38, name: "Salaj"},
    {id: 35, name: "Satu Mare", coordinates: {lat: 47.7936288, lng: 22.8533234}},
    {id: 41, name: "Sibiu", coordinates: {lat: 45.7947822, lng: 24.1271349}},
    {id: 6, name: "Suceava", coordinates: {lat: 47.6696358, lng: 26.2692016}},
    {id: 12, name: "Teleorman", coordinates: {lat: 43.9718072, lng: 25.3338167}},
    {id: 15, name: "Timis", coordinates: {lat: 45.7527242, lng: 21.2193828}},
    {id: 14, name: "Tulcea", coordinates: {lat: 45.1723712, lng: 28.7963715}},
    {id: 27, name: "Valcea", coordinates: {lat: 45.1095947, lng: 24.3713843}},
    {id: 39, name: "Vaslui"},
    {id: 28, name: "Vrancea"}
  ];

  constructor() {
    this.setConfigData();
  }

  setConfigData() {
    this.configData = {
      layout: {
        rtl: false, // options:  true & false
        variant: 'light', // options:  light & dark
        theme_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        logo_bg_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        sidebar: {
          collapsed: false, // options:  true & false
          backgroundColor: 'light', // options:  light & dark
        },
      },
    };
  }

  getCounty(): string {
    return localStorage.getItem('county');
  }

  getCountyCoords(selectedCounty = null): Coordinate {

    if(typeof selectedCounty == 'undefined' && !selectedCounty){
      selectedCounty = this.getCounty();
    }

    let result = this.counties.find((county) => {
      return county.name === selectedCounty;
    });

    return result ? new Coordinate(result.coordinates.lat, result.coordinates.lng) : null;
  }
}
