<div>
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <mat-tab-group disablePagination="true" stretchTabs="true" [selectedIndex]="selectedTab">
      <mat-tab label="Pro">
        <div class="menu">
          <ul class="list"
              [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
              [perfectScrollbar]>
            <!-- Top Most level menu -->
            <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
                *ngFor="let sidebarItem of sidebarItems"
                [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
              <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
              <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
                 [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
                 class="menu-top">
                <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
                <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
                <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
              </a>
              <!-- First level menu -->
              <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
                    *ngFor="let sidebarSubItem of sidebarItem.submenu"
                    [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                     (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                    {{sidebarSubItem.title | translate}}
                  </a>
                  <!-- Second level menu -->
                  <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                    <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                        [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                        [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                      <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                         (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                         [ngClass]="[sidebarSubsubItem.class]">
                        {{sidebarSubsubItem.title | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </mat-tab>
      <mat-tab label="Transfer">
        <div class="menu">
          <ul class="list" [perfectScrollbar]
              [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}">
            <!-- Top Most level menu -->
            <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
                *ngFor="let sidebarItem of sidebarItemsTransfer"
                [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
              <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
              <a class="menu-top" [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
                 [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)">
                <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
                <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
                <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
              </a>
              <!-- First level menu -->
              <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
                    *ngFor="let sidebarSubItem of sidebarItem.submenu"
                    [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                     (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                    {{sidebarSubItem.title | translate}}
                  </a>
                  <!-- Second level menu -->
                  <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                    <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                        [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                        [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                      <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                         (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                         [ngClass]="[sidebarSubsubItem.class]">
                        {{sidebarSubsubItem.title | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </mat-tab>
    </mat-tab-group>
  </aside>
</div>
