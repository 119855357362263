// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

   standardApiUrl: 'https://juno.gocab.eu:44331/api',
   apiUrl: "https://juno.gocab.eu:44331/api/pro",
   apiTransferUrl: "https://juno.gocab.eu:44331/api/transfers",
   paymentsApiUrl: "https://juno.gocab.eu:44334/api/pro",
   paymentApiTransferUrl: "https://juno.gocab.eu:44334/api/transfers",
   standardUrl: 'https://juno.gocab.eu/#/',
   standardUrlHost: 'https://juno.gocab.eu',

  /*
  standardApiUrl: 'https://localhost:44379/api',
  apiUrl: "https://localhost:44379/api/pro",
  apiTransferUrl: "https://localhost:44379/api/transfers",
  paymentsApiUrl: "https://localhost:44315/api/pro",
  paymentApiTransferUrl: "https://localhost:44315/api/transfers",
  standardUrl: 'http://127.0.0.1:8001/src/#/',
  standardUrlHost: 'http://127.0.0.1:8001',*/

  socketServerUrl: 'athena.gocab.eu',
  socketServerPort: 21883,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
